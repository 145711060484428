@font-face {
  font-family: "Karla";
  src: url("../fonts/Karla/Karla-VariableFont_wght.ttf") format("truetype");
  /* Add more src lines for different font formats if available */
  font-weight: normal;
  font-style: normal;
}

@media only screen and (min-width: 0px) {
  #wrapper {
    padding-left: 5%;
    padding-right: 10%;
    padding-top: 4%;
  }

  #jobs {
    flex-direction: column;
    align-items: center;
    display: block;
  }
  #foot {
    flex-direction: column;
  }
  #link {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  #wrapper {
    padding-left: 5%;
    padding-right: 35%;
    padding-top: 4%;
  }

  #jobs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  #foot {
    flex-direction: row;
  }
  #link {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1920px) {
  #wrapper {
    padding-left: 5%;
    padding-right: 60%;
    padding-top: 4%;
  }
}

.html {
  padding: 8px;
}

html,
body {
  background-color: #161616;
  font-family: "Karla", sans-serif;
}

#title {
  font-size: 24px;
  margin-bottom: 80px;
}
.inf {
  font-size: 24px;
  color: #f1f1f1;
  margin-bottom: 80px;
}
#jobs {
  font-size: 16px;
  margin-bottom: 40px;
  padding-left: 20px;
}
.footerItem {
  padding-right: 10px;
  font-size: 14px;
}
#copyright {
  font-size: 14px;
  padding-right: 40px;
}
.pr {
  color: #858585;
  padding-right: 40px;
}
#foot {
  padding: 30px 0px 30px 0px;
  display: flex;
}
#body {
  font-size: 24px;
  color: #f1f1f1;
}
#one {
  font-size: 16px;
  color: #404040;
  color: #f1f1f1;
}
a {
  color: #b6b6b6;
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  color: #f5c242;
}
.l {
  padding-left: 0.8em;
}

.noHover {
  color: #f1f1f1;
  pointer-events: none;
}

span:hover {
  text-decoration: none;
  color: #f5c242;
}

/*sidebar*/
span.hamburger {
  font-size: 30px;
  cursor: pointer;
  float: right;
  padding-right: 16px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 21px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
