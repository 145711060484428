/* Always set the map height explicitly to define the size of the div
* element that contains the map. */
#map {
    height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
}

/*Set CSS for the control border.*/
#controlUI, #projectUI {
    background-color: #303030;
    border: 2px solid #303030;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    cursor: pointer;
    float: left;
    margin-bottom: 22px;
    text-align: center;
}

/*Set CSS for the control interior.*/
#controlText, #projectText {
    color: #A9A9A9;
    font-family: Roboto,Arial,sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding-left: 3px;
    padding-right: 3px;
}

#projectUI {
    margin-left: 12px;
}